nav {
  position: inherit;
  top: 0px;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: flex-start;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 3;

}

.logos-container {
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
}

.list {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  align-content: center;
}

.items {
  margin-right: 40px;
  font-size: 28px;
  font-weight: 500;
  padding: 0px 10px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-content: flex-start;
  flex-direction: column;
  color: #6e817f;
  text-decoration: "none" !important;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  margin-bottom: 0px;
  border-bottom: 0px solid #0362af;
  position: relative;

  &:hover {
    background: #3c3c3c0f;
  }
}

.items::after {
  content: "";
  width: 0px;
  height: 0px;
  left: calc(50% - 2px);
  border-radius: 10px;
  background: #ffdd0200;
  bottom: 0px;
  position: absolute;
  // transition   : all 400ms cubic-bezier(0.275, 0.82, 0.165, 1);
  transition: height 400ms cubic-bezier(0.275, 0.82, 0.165, 1),
    width 400ms cubic-bezier(0.275, 0.82, 0.165, 1),
    top 400ms cubic-bezier(0.275, 0.82, 0.165, 1),
    left 400ms cubic-bezier(0.275, 0.82, 0.165, 1),
    bottom 400ms cubic-bezier(0.275, 0.82, 0.165, 1),
    background 400ms cubic-bezier(0.275, 0.82, 0.165, 1),
    transform 1000s cubic-bezier(0.275, 0.82, 0.165, 1);
  transform: rotate(45deg);
}

.items:hover::after {
  content: "";
  width: 2px;
  height: 30px;
  border-radius: 100px;
  background: #0362af;
  left: calc(50%);
  bottom: -15px;
  position: absolute;
  transition: height 400ms cubic-bezier(0.275, 0.82, 0.165, 1),
    width 400ms cubic-bezier(0.275, 0.82, 0.165, 1),
    top 400ms cubic-bezier(0.275, 0.82, 0.165, 1),
    left 400ms cubic-bezier(0.275, 0.82, 0.165, 1),
    bottom 400ms cubic-bezier(0.275, 0.82, 0.165, 1),
    background 400ms cubic-bezier(0.275, 0.82, 0.165, 1),
    transform 0ms cubic-bezier(0.275, 0.82, 0.165, 1);
  transform: rotate(90deg);
}

.items-selected {
  margin-right: 40px;
  font-size: 28px;
  padding: 0px 10px;
  cursor: pointer;
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-content: flex-start;
  flex-direction: column;
  color: #0362af;
  font-weight: 400;
  text-decoration: "none" !important;
  background: #ffdd0204;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
}

.items-selected::after {
  content: "";
  width: 100%;
  height: 5px;
  left: 0px;
  background: #0362af;
  bottom: -5px;
  position: absolute;
  transition: all 300ms cubic-bezier(0.275, 0.82, 0.165, 1);
  border-radius: 0px;
  transform: rotate(0deg);
}

.menu-toggle-btn {
  display: none;
}

.anybodies-logo {
  width: 50px;
  height: 40px;
  cursor: pointer;
  object-fit: contain;
}

.anybodies-brandname {
  width: 240px;
  object-fit: fill;
  margin-left: 11px;
  z-index: 3;
  position: relative;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .anybodies-brandname {
    width: 160px;
    object-fit: fill;
    margin-left: 11px;
    z-index: 3;
    position: relative;
    cursor: pointer;
  }
}


@media screen and (max-width: 800px) {
  nav {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    z-index: 3;
    background: white;
    box-shadow: 0px 0px 36px #00000030;

    a {
      padding: 0px !important;
      text-align: center;
      width: 100%;
      display: flex !important;
      background: #ffffff0a;
      align-items: center !important;
      opacity: 1;
      border: 1px solid #ffffff14;
    }
  }

  .list {
    flex-direction: column;
    height: auto;
    height: 100vh;
    padding: 0px;
    margin: 0px;
  }

  .menu-toggle-btn {
    display: block;
    right: 10px;
    top: 7px;
    padding: 5px;
    color: #000;
    font-size: 18px;
    z-index: 2;
    margin-right: 20px;
  }

  .mobile-menu-hidden {
    position: absolute;
    width: 100vw;
    height: 0vh;
    background: rgba(0, 0, 0, 0);
    opacity: 0;
    top: 0px;
    left: 0px;
    z-index: -1;
  }

  .menu-item-container {
    margin: auto 0px;
    width: 100%;
  }

  .mobile-menu-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.9);
    opacity: 1;
    transition: all 0.2s ease-in-out;
    top: 0px;
    left: 0px;
    z-index: 2;
    display: flex;
    animation: fadeIn 0.3s ease-in;
    backdrop-filter: blur(15px) saturate(100%);
  }

  .anybodies-logo {
    width: 50px;
    height: 40px;
    object-fit: contain;
    margin-left: 20px;
    z-index: 3;
    position: relative;
    cursor: pointer;
  }

  .items {
    margin-right: 40px;
    font-size: 22px;
    font-weight: 200;
    padding: 0px 10px;
    cursor: pointer;
    height: 64px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-content: flex-start;
    flex-direction: column;
    color: #282828;
    text-decoration: "none" !important;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }

    margin-bottom: 0px;
    border-bottom: 0px solid #000000;
    position: relative;

    &:hover {
      background: #ffdd0204;
    }
  }

  .items-selected {
    margin-right: 40px;
    font-size: 22px;
    padding: 0px 10px;
    cursor: pointer;
    height: 64px;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-content: flex-start;
    flex-direction: column;
    color: #ffffff;
    font-weight: 500;
    text-decoration: "none" !important;
    background: #0362af;
;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
  }

  .items-selected:after {
    content: none;
  }
}
