.preview-main-container {
  margin-top: 65px;
  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    display: none;
  }

  .preview-asd {
    width: auto;
    display: flex;
    flex-direction: row;
    overflow: auto;
    padding-bottom: 30px;
    padding-inline: 100px;
    justify-content: space-between;
  }
}

// /* On screens that are 992px or less, set the background color to blue */
// @media screen and (max-width: 992px) {
// }

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 992px) {
  // @media screen and (max-width: 600px) {
  .preview-asd {
  }
}

// Mobile View
@media screen and (max-width: 600px) {
  .preview-main-container .preview-asd.disable-selection {
    display: none;
  }
}

@media screen and (min-width: 601px) {
  .carousel-view-mobile {
    display: none;
  }
}
