.mint-raffle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  margin-top: 100px;
  gap: 20px;


  .presale-title {
    font-size: 3rem;
    text-shadow: 0px 5px 10px #ffeb3b8a;
    color: #009406;
  }
}

.wallet-btn {
  background: white !important;
  font-family: inherit !important;
  font-weight: 200 !important;
  font-size: 18px !important;
  color: #0362af !important;
  padding: 0px 40px !important;
  border: 2px solid #0362af !important;
  box-shadow: 6px 8px 0px #0362af ;
}
