$width: 30vw;
$border-size: 0.02;
$inner-margin: 0.02;
$bg-ratio: 0.7;
$geof-ratio: 0.7;
$geof-ratio-show: 0.9;
$ani-speed: 200ms;

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  * > .info-container {
    .title {
      font-size: 1.2rem !important;
    }
  }
}

/* On screens that are 600px or less, set the background color to olive */

.carousel-item-main-container {
  cursor: pointer;
  .carousel-item-container {
    // width: 160px;
    // height: 160px;
    width: $width;
    height: $width;
    position: relative;

    .bg-container {
      position: absolute;
      z-index: 1;
      bottom: 0px;
      //   margin: 10px;
      margin: calc(#{$width} * #{$border-size});
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.495);
      //   border-radius: 3px;
      border-radius: calc(#{$width} * #{$border-size});
      background: white;
      .bg-image {
        // width: 100px;
        // height: 100px;
        width: calc(#{$width} * #{$bg-ratio});
        height: calc(#{$width} * #{$bg-ratio});
        display: block;
        margin: calc(#{$width} * #{$inner-margin});
        border-radius: calc(#{$width} * #{$border-size});
      }
    }

    .geof-container {
      position: absolute;
      z-index: 1;
      bottom: 0px;
      margin: calc(#{$width} * #{$border-size});
      transition: all 0.5s;
      .geof-image {
        transition: all 0.5s;
        width: calc(#{$width} * #{$geof-ratio-show});
        height: calc(#{$width} * #{$geof-ratio-show});
        display: block;
        margin: calc(#{$width} * #{$inner-margin});
        border-radius: calc(#{$width} * #{$border-size});
        // transform: translateX(
        //   calc((#{$geof-ratio-show} - #{$bg-ratio}) * #{$width} * -0.5)
        // );
      }
    }

    .geof-container-show {
      transition: all $ani-speed ease-in-out;
      position: absolute;
      z-index: 1;
      bottom: 0px;
      margin: calc(#{$width} * #{$border-size});
      $geof-ratio: 0.7;
      .geof-image-show {
        transition: all $ani-speed ease-in-out;
        width: calc(#{$width} * #{$geof-ratio});
        height: calc(#{$width} * #{$geof-ratio});
        display: block;
        margin: calc(#{$width} * #{$inner-margin});
        border-radius: calc(#{$width} * #{$border-size});
        // transform: translateX(
        //   calc((#{$geof-ratio} - #{$bg-ratio}) * #{$width} * -0.5)
        // );
      }
    }

    .info-container {
      transition: all $ani-speed ease-in-out;
      position: absolute;
      z-index: 2;
      bottom: 0px;
      width: calc(#{$width} * #{$geof-ratio});
      height: calc(#{$width} * #{$geof-ratio});
      display: block;
      margin: calc(#{$width} * #{$border-size} + #{$width} * #{$inner-margin});
      border-radius: calc(#{$width} * #{$border-size});
      transform: translateX(
        calc((((#{$geof-ratio} - #{$bg-ratio})) * -0.5) * #{$width})
      );
      background: #ffffffba;
      backdrop-filter: blur(11px);
      opacity: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 14px;
      * > {
        transition: all $ani-speed ease-in-out;
      }
      &:hover {
        backdrop-filter: blur(3px);
        background: #ffffffba;
        .title {
          opacity: 0.8;
        }

        .desc {
          opacity: 0.8;
        }
      }

      .title {
        color: #e9155b;
        font-size: 2rem;
        &::after{
          content: 'click to reveal';
          display: block;
          font-size: 12px;
          color: #999;
        }
      }

      .desc {
        font-size: 1rem;
        width: 90%;
        color: #0362af
      }
    }
    .hide {
      transition: all $ani-speed ease-in-out;
      opacity: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  $width: 93vw;
  $border-size: 0.02;
  $inner-margin: 0.02;
  $bg-ratio: 0.7;
  $geof-ratio: 0.7;
  $geof-ratio-show: 0.9;
  $ani-speed: 200ms;

  .carousel-item-main-container {
    cursor: pointer;
    .carousel-item-container {
      // width: 160px;
      // height: 160px;
      width: $width;
      height: $width;
      position: relative;

      .bg-container {
        position: absolute;
        z-index: 1;
        bottom: 0px;
        //   margin: 10px;
        margin: calc(#{$width} * #{$border-size});
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.495);
        //   border-radius: 3px;
        border-radius: calc(#{$width} * #{$border-size});
        .bg-image {
          // width: 100px;
          // height: 100px;
          width: calc(#{$width} * #{$bg-ratio});
          height: calc(#{$width} * #{$bg-ratio});
          display: block;
          margin: calc(#{$width} * #{$inner-margin});
          border-radius: calc(#{$width} * #{$border-size});
        }
      }

      .geof-container {
        position: absolute;
        z-index: 1;
        bottom: 0px;
        margin: calc(#{$width} * #{$border-size});
        transition: all 0.5s;
        .geof-image {
          transition: all 0.5s;
          width: calc(#{$width} * #{$geof-ratio-show});
          height: calc(#{$width} * #{$geof-ratio-show});
          display: block;
          margin: calc(#{$width} * #{$inner-margin});
          border-radius: calc(#{$width} * #{$border-size});
          // transform: translateX(
          //   calc((#{$geof-ratio-show} - #{$bg-ratio}) * #{$width} * -0.5)
          // );
        }
      }

      .geof-container-show {
        transition: all $ani-speed ease-in-out;
        position: absolute;
        z-index: 1;
        bottom: 0px;
        margin: calc(#{$width} * #{$border-size});
        $geof-ratio: 0.7;
        .geof-image-show {
          transition: all $ani-speed ease-in-out;
          width: calc(#{$width} * #{$geof-ratio});
          height: calc(#{$width} * #{$geof-ratio});
          display: block;
          margin: calc(#{$width} * #{$inner-margin});
          border-radius: calc(#{$width} * #{$border-size});
          // transform: translateX(
          //   calc((#{$geof-ratio} - #{$bg-ratio}) * #{$width} * -0.5)
          // );
        }
      }

      .info-container {
        transition: all $ani-speed ease-in-out;
        position: absolute;
        z-index: 2;
        bottom: 0px;
        width: calc(#{$width} * #{$geof-ratio});
        height: calc(#{$width} * #{$geof-ratio});
        display: block;
        margin: calc(
          #{$width} * #{$border-size} + #{$width} * #{$inner-margin}
        );
        border-radius: calc(#{$width} * #{$border-size});
        transform: translateX(
          calc((((#{$geof-ratio} - #{$bg-ratio})) * -0.5) * #{$width})
        );
        background: hsla(0, 0%, 0%, 0.28);
        backdrop-filter: blur(11px);
        opacity: 1;
        background: #ffffffba;
        backdrop-filter: blur(11px);
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .title {
          color: #e9155b;
          font-size: 1.5rem;
        }

        .desc {
          width: 90%;
          color: #0362af
        }
      }
      .hide {
        transition: all $ani-speed ease-in-out;
        opacity: 0;
      }
    }
  }
}

// Mobile View
@media screen and (max-width: 600px) {
  .carousel-view-mobile {
    margin-top: calc(70px + 2em);
    .react-multiple-carousel__arrow{
      &--left,
      &--right{
        z-index: 1;
      }
      &--left{
        left: 5px;
      }
      &--right{
        right: 5px;
      }
    }
    .carousel-item-main-container{
      padding-top: 15%;
      .carousel-item-container{
        width: auto;
        height: auto;
        margin: 0 20px;
        border-radius: calc(93vw * 0.02);
        background-color: #ffffffba;
        padding: 0.5em;
        .bg-container {
          position: relative;
          margin: 0;
          box-shadow: none;
          background: transparent;
          .bg-image {
            width: 100%;
            height: auto;
            margin: 0;
          }
        }
        .info-container{
          width: 100%;
          height: 100%;
          margin: 0;
          bottom: 0;
          left: 0;
          right: 0;
          .title::after {
            content: none;
          }
        }
        .geof-container {
          margin: 0;
          .geof-image{
            margin: 0 0 19%;
            height: auto;
            width: 100%;
          }
        }
        .geof-container-show {
          .geof-image-show {
            width: 90%;
            height: auto;
            margin: 0;
          }
        }
        .hide {
          opacity: 1;
          height: auto;
          top: auto;
          height: 20%;
          background-color: #fff;
        }
      }
    }
  }
}
