@import "~antd/dist/antd.css"; // Introduce the official dark less style entry file

@media screen and (min-width: 601px) {
  &::-webkit-scrollbar {
    width: 0.5em;
    z-index: 100;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0362af;
    border-width: 1px 0 2px;
    border-radius: 20px;
    border-style: solid;
    border-color: #ffffff;
  }
}

.App {
  background: #ffffff63;
  overflow-x: hidden;
  min-height: 100vh;
}

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }
