$ani-speed: 200ms;

.preview-overlay-main-container {
  transition: all $ani-speed ease-in-out;
  width: 0vw;
  position: absolute;
  height: 100vh;
  bottom: 0px;
  background: transparent;
  overflow: none;
  z-index: 10;
}

.preview-overlay-main-container-show {
  cursor: pointer;
  transition: all $ani-speed ease-in-out;
  width: 100vw;
  position: fixed;
  height: 100vh;
  bottom: 0px;
  background: #1a3e00d4;
  z-index: 10;
  backdrop-filter: blur(20px);
  overflow: none;
}
