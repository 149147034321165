$buttonColor: #009406;
$buttonColorDisabled: gray;
$mintButtonColor: #0362af;

.mint-block {
  text-align: center;
  width: 100%;
  .select-price-container {
    width: 100%;
    color: red !important;
  }
  .counter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
    input[type="number"] {
      display: inline-block;
      font-size: 3em;
      width: 3em;
      border: 0;
      background-color: transparent;
      text-align: center;
      padding: 0;
      margin: 0.5em 0.25em 0;
      outline: none;
      background: #f8f8f8;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
    button {
      width: 1.5em;
      height: 1.5em;
      font-size: 2em;
      line-height: 1.5em;
      text-align: center;
      cursor: pointer;
      border-radius: 4px;
      background-color: white;
      font-family: inherit;
      color: $buttonColor;
      border: 2px solid $buttonColor;
      box-shadow: 6px 8px 0px $buttonColor;
      &:disabled {
        color: $buttonColorDisabled;
        border-color: $buttonColorDisabled;
        box-shadow: 6px 8px 0px $buttonColorDisabled;
        cursor: default;
      }
    }
  }
  .mint-button {
    padding: 0.5em 0px;
    width: 80%;
    font-size: 2em;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    background-color: white;
    font-family: inherit;
    color: $mintButtonColor;
    border: 2px solid $mintButtonColor;
    box-shadow: 6px 8px 0px $mintButtonColor;
    margin-bottom: 1em;
    &:disabled {
      color: $buttonColorDisabled;
      border-color: $buttonColorDisabled;
      box-shadow: 6px 8px 0px $buttonColorDisabled;
      cursor: default;
    }
    &:hover {
      background-color: #0362af12;
    }

    .total {
      font-size: 0.8rem;
      color: #707070;
    }
  }
  * > .purchased-items {
    color: #009402;
    font-size: 1.2rem;
  }
}

.buttonLoader {
  padding: 1em;
}
