.mint-raffle-main-container {
  width: 40%;
  background: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 7px 15px #1c005521;

  .presale-link-container {
    width: 100%;
    display: flex;
    .presale-button {
      color: #0362af;
      margin: auto;
    }
  }

  .disclaimer {
    font-size: 0.8rem;
    color: #5e5e5e;
    padding-inline: 100px;
    text-align: center;
    margin-top: 30px;
    font-family: system-ui;
    font-weight: 700;
  }

  .sold-out {
    text-align: center;
    font-size: 3rem;
    color: #ea145b;
    margin-top: 20px;
  }

  .no-wl {
    border: 1px solid #ea145b;
    text-align: center;
    color: red;
    padding-block: 10px;
    margin-top: 37px !important;
    width: 300px;
    margin: auto;
    margin-bottom: 20px;
  }

  .opens-in {
    text-align: center;
    color: #62a770;
    margin-top: 40px;
  }
  .ends-in {
    text-align: right;
    color: #62a770;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
  }

  .pre-title {
    text-align: center;
    font-size: 1.5rem;
    color: #0362af;
  }

  .center-items {
    display: flex;
    justify-content: center !important;
    align-items: center;
    gap: 40px;
    text-align: center;
  }

  .raflle-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .stage-container {
      .title {
        color: #62a770;
      }
      .label {
        font-size: 0.7rem;
        color: #ea145b;
      }
    }
    .timer {
    }
  }

  .mint-desc {
    font-size: 0.9rem;
    padding: 20px;
    color: #009688;
  }

  .stats-container {
    display: flex;
    justify-content: center;
    .percent-container {
      display: flex;
      justify-content: center;
      color: #187ddc;
      margin-top: 40px;
    }
  }

  .progress-container {
    progress {
      border-radius: 70px;
      width: 100%;
      height: 30px;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    }

    progress::-webkit-progress-bar {
      background-color: #c8c8c8;
      border-radius: 70px;
    }

    progress::-webkit-progress-value {
      background-color: #06ffc3;
      border-radius: 70px;
      box-shadow: 0px 0px 5px 3px #06ffc56c;
    }
  }
  .time-left {
    font-size: 15px;
    font-weight: 100;

    .items-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .countdown-divider {
        height: 11px;
        width: 1px;
        background: #0000001a;
        margin-inline: 2px;
      }

      .item-container {
        position: relative;
        margin-inline: 7px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        min-width: 67px;

        .item-value {
          position: relative;
          font-size: 30px;
          font-weight: 600;
          margin-right: 3px;
          color: #ea145b;
        }

        .item-title {
          position: relative;
          font-size: 17px;
          color: #62a770;
          font-weight: 500;
        }
      }
    }
  }
  * > .time-left-end {
    font-size: 15px;
    font-weight: 100;

    .items-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .countdown-divider {
        height: 11px;
        width: 1px;
        background: #0000001a;
        margin-inline: 2px;
      }

      .item-container {
        position: relative;
        margin-inline: 4px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        min-width: 20px;

        .item-value {
          position: relative;
          font-size: 0.7rem;
          font-weight: 600;
          margin-right: 3px;
          color: #ea145b;
        }

        .item-title {
          position: relative;
          font-size: 0.7rem;
          color: #62a770;
          font-weight: 500;
        }
      }
    }
  }
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .mint-raffle-main-container {
    width: 90%;
    background: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 7px 15px #1c005521;

    .disclaimer {
      font-size: 0.6rem;
      color: #5e5e5e;
      padding-inline: 10px;
      text-align: center;
      margin-top: 30px;
      font-family: system-ui;
      font-weight: 700;
    }

    .raflle-info-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .stage-container {
        .title {
        }
        .label {
          font-size: 0.7rem;
          color: #ea145b;
        }
      }
      .timer {
      }
    }

    .mint-desc {
      font-size: 0.9rem;
      padding: 20px;
      color: #009688;
    }

    .stats-container {
      display: flex;
      justify-content: center;
      .percent-container {
        display: flex;
        justify-content: center;
      }
    }

    .progress-container {
      progress {
        border-radius: 70px;
        width: 100%;
        height: 30px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
      }

      progress::-webkit-progress-bar {
        background-color: #30383d5d;
        border-radius: 70px;
      }

      progress::-webkit-progress-value {
        background-color: #06ffc3;
        border-radius: 70px;
        box-shadow: 0px 0px 5px 3px #06ffc56c;
      }
    }
  }
}

.mint-block-not-connect-wallet {
  text-align: center;
  color: #999;
  margin-block: 34px;
}
