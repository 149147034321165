.blueprint-disclaimer {
  position: absolute;
  bottom: 20px;
  z-index: 1;
  width: 100vw;
  left: 0px;
  text-align: center;
  color: white;
  font-size: 0.6rem;
}

@media screen and (max-width: 1000px) {
  .blueprint-disclaimer {
    display: none !important;
  }
}
