footer {
	text-align: center;
	padding: 3em 0;
	margin-top: 2em;
	@media screen and (max-width: 600px) {
		padding: 1.5em 0;
	}
}

.social-items-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 1em;
}

.social-item {
	align-content: center;
	background-color: rgba(255, 255, 255, 0.75);
	border-radius: 50px;
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	margin-inline: 10px;
	height: 40px;
	width: 40px;
	transition: all .3s;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
	position: relative;
	&:hover {
		background-color: rgba(255, 255, 255, 0.85);
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
		transform: translateY(-1px);
	}
	&:active {
		background-color: rgba(255, 255, 255, 1);
	}
	img {
		margin: auto;
    max-height: 15px;
    max-width: 15px;
    object-fit: contain;
		fill: #000;
	}
}
