body {
  font-family: "Luckiest Guy", cursive, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./Components/CarouselPreview/images/bg/bg6.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0px;
  padding: 0px;
  background-color: #cbe6ed !important;
  color: #000 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
}

.disable-selection {
  -webkit-touch-callout: none !important;
  /* iOS Safari */
  -webkit-user-select: none !important;
  /* Safari */
  -khtml-user-select: none !important;
  /* Konqueror HTML */
  -moz-user-select: none !important;
  /* Old versions of Firefox */
  -ms-user-select: none !important;
  /* Internet Explorer/Edge */
  user-select: none !important;
  /* Non-prefixed version, currently */
}
