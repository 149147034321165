$star-color: #edbf00;

.roadmap-presentation {
	overflow: hidden;
	width: 100vw;
	height: 100vh;
  height: var(--doc-height);
	position: relative;
	.roadmap-presentation-image {
		min-width: 100vw;
		min-height: 100vh;
    min-height: var(--doc-height);
		position: absolute;
		overflow: hidden;
		aspect-ratio: 59/30;
		transform: translateX(-50%) translateY(-50%);
		left: 50%;
		top: 50%;
		img {
			max-width: 100%;
			height: auto;
		}
	}
	.roadmap-marker-container {
		position: absolute;
	}
}

.roadmap-header {
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  padding: 1em;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .back-button {
    color: '#000';
    background-color: #fff;
    &:hover,
    &:focus {
      color: $star-color;
      border-color: $star-color;
    }
  }
  .get-image {
    background-color: transparent;
    color: #fff;
    &:hover,
    &:focus {
      color: $star-color;
      border-color: $star-color;
    }
  }
}

$fill-active: $star-color;
$stroke-inactive: rgba($fill-active, 0.75);

.roadmap-page {
  overflow: hidden !important;
  user-select: none;
  footer,
  nav {
    display: none;
  }
}

.star {
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: transform 150ms ease-in;

  .icon-star {
    width: 40px;
    height: 46px;
    vertical-align: middle;
  }

  .icon-star-path {
    stroke: #FFF;
    fill: $fill-active;
  }

  .icon-star-celebrate {
    opacity: 0;
    pointer-events: none;
  }

  &:hover,
  &.pressed {
    transform: scale(1.5);
    .pulse-star {
      display: none;
    }
  }

  &.pressed {
    .icon-star-path {
      fill: #FFF;
    }
    .icon-star {
      animation: grow 0.35s ease-in forwards;
      transform-origin: 50% 50%;
    }

    .icon-star-celebrate {
      opacity: 1;
    }

    [class^='icon-star-stripe'] {
      opacity: 0;
      stroke: #FFF;
      stroke-dasharray: 25;
      animation: wavvy 0.5s linear forwards;
      stroke-dashoffset: 0;

      @for $i from 1 through 6 {
        &:nth-child(#{$i}) {
          animation-delay: (0.1s * $i);
        }
      }

      &:nth-child(1),
      &:nth-child(6) {
        stroke-dashoffset: -25;
        animation-name: wavvy-reverse;
      }
    }

    .icon-star-dot {
      opacity: 0;
      animation: appear 0.2s ease forwards;
      transform-origin: 50% 50%;
      fill: rgba($fill-active, 0.5);

      @for $i from 1 through 7 {
        &:nth-child(#{$i}) {
          animation-delay: (0.05s * $i);
        }
      }
    }
  }
}

.pulse-star {
  svg {
    max-width: 100%;
    max-height: 100%;
    animation: star-big-pulse 2s -0.2s ease-in-out infinite;
  }
  width: 18px;
  height: 17px;
  position: absolute;
  z-index: -1;
  top: 30%;
  left: 29%;
  transform-origin: 50%;
  fill: #fff;
}

@keyframes star-big-pulse {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
      transform: scale(3);
      opacity: 0;
  }
}

@keyframes wavvy {
  0% {
    opacity: 1;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -25;
    opacity: 0;
    transform: translateY(5px);
  }
}

@keyframes wavvy-reverse {
  0% {
    opacity: 1;
    stroke-dashoffset: -25;
  }

  100% {
    stroke-dashoffset: 0;
    opacity: 0;
    transform: translateY(-5px);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  66% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }

  66% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

.roadmap-mobile-carousel {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: var(--doc-height);
  &-item {
    color: #fff;
    flex-basis: 1;
    .title {
      font-weight: 900;
      font-size: 1.25rem;
      line-height: 1.75;
    }
    .description {
      font-size: 1.75rem;
      line-height: 1.25;
      font-weight: 300;
    }
  }
  .react-multi-carousel-list,
  .react-multi-carousel-track {
    height: 100%;
  }
  .react-multi-carousel-item {
    display: flex;
    align-items: flex-end;
    padding: 1em 1em 2em;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 50%);
  }
  .react-multi-carousel-dot-list {
    justify-content: space-between;
    padding: 0 .25rem;
    gap: 0.25rem;
    z-index: 2;
    > li {
      flex-basis: 100%;
    }
  }
  .react-multi-carousel-dot {
    button {
      width: 100%;
      height: auto;
      padding-top: 1.5rem;
      background: transparent;
      border-radius: 0;
      border-width: 0 0 3px 0;
      border-color: #fff;
      opacity: 0.2;
      transition: all 250ms;
      &:hover:active {
        opacity: 1;
      }
    }
    &--active {
      button {
        opacity: 1;
      }
    }
  }
}

@media screen and (min-width: 1001px) {
  .roadmap-mobile-carousel{
    display: none;
  }
  .roadmap-presentation .roadmap-presentation-image img {
    transform: none !important;
    height: auto!important;
  }
}


@media screen and (max-width: 1001px) {
  .roadmap-presentation {
    .roadmap-presentation-image img {
      transition-duration: 1.5s;
      transition-timing-function: cubic-bezier(.4,0,.2,1);
      max-width: none;
    }
  }
  .roadmap-marker-container {
    display: none;
  }
}
